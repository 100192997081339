import { IS_RECAPTCHA_ENTERPRISE_ENABLED, tokens } from "gatsby-env-variables"

export const loadScriptCaptcha = () => {
  const sitekey = tokens.RECAPTCHA
  const script = document.createElement("script")
  script.src = `https://www.google.com/recaptcha/${
    IS_RECAPTCHA_ENTERPRISE_ENABLED ? "enterprise" : "api"
  }.js?render=${sitekey}`
  document.body.appendChild(script)
}

export const executeRecaptcha = async () => {
  const method = IS_RECAPTCHA_ENTERPRISE_ENABLED
    ? grecaptcha.enterprise.execute
    : grecaptcha.execute

  return await method(tokens.RECAPTCHA, {
    action: "submit",
  })
}
