import { IS_RECAPTCHA_ENTERPRISE_ENABLED, tokens } from "gatsby-env-variables"

// TODO: mover esses utils para lugares categorizados, igual fizemos com os formatters
export const getUrlParams = (param) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(param)
}

export const loadScriptCaptcha = () => {
  const sitekey = tokens.RECAPTCHA
  return new Promise((resolve) => {
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/${
      IS_RECAPTCHA_ENTERPRISE_ENABLED ? "enterprise" : "api"
    }.js?render=${sitekey}`
    document.body.appendChild(script)
    resolve(true)
  })
}

export const isEmptyDate = (value) => {
  if (value === "0001-01-01T00:00:00Z") return true
  return false
}

export const hasValidValue = (value) => {
  return (
    ![undefined, null, 0, "", "R$ 0,00", "0 anos", "0 meses"].includes(value) &&
    !isEmptyDate(value)
  )
}

export const objectToList = (data) =>
  Object.keys(data).map((key) => {
    return { key, value: data[key] }
  })

export const getDefaultValues = (fields) => {
  const radioFields = objectToList(fields).reduce((accFields, { value }) => {
    if (value.type === "radio") {
      const { options, name } = value
      const reducedOptions = options.reduce((accOptions, option) => {
        if (option?.checked) {
          return [name, option.value]
        }
        return accOptions
      }, [])
      return [...accFields, reducedOptions]
    }
    if (value.type === "select" && value.defaultValue !== undefined) {
      const reducedOptions = [value.name, value.defaultValue]
      return [...accFields, reducedOptions]
    }
    return accFields
  }, [])
  const defaultValues = Object.fromEntries(radioFields)
  return defaultValues
}

export const removeLastBackslash = (url) => {
  if (!url) return ""
  if (url.endsWith("/")) {
    return url.slice(0, -1)
  }
  return url
}

export const generateApiStepInfo = ({ steps, targetStep }) => {
  const current_form_step = steps[targetStep].name
  const stepKeys = Object.keys(steps)
  const stepIndex = stepKeys.findIndex((key) => key === targetStep)
  const previous_form_step =
    stepIndex === 0
      ? steps[stepKeys[0]].name
      : steps[stepKeys[stepIndex - 1]].name
  const stepInfo = {
    current_flow_name: "default",
    current_form_step,
    previous_form_step,
  }
  return stepInfo
}

export const getFirstName = (fullName) => {
  if (!fullName) return ""
  if (typeof fullName !== "string") return fullName
  return fullName.split(" ")[0]
}

export const preventNumberOnInput = (event) => {
  if (event?.target?.value) {
    event.target.value = event.target.value.replace(/[0-9]/gm, "")
  }
}

export const onlyNumbersOnInput = (event) => {
  if (event?.target?.value) {
    event.target.value = event.target.value.replace(/[^0-9]/gm, "")
  }
}

export const getSearchParamsFromUrl = () => {
  const windowLocation = typeof window !== "undefined" && window.location
  const search = windowLocation?.search
  const params = search !== undefined && search !== "undefined" ? search : ""
  return params
}

export const getCompleteHomeLocation = ({
  withParams = true,
  proposalRoute = "proposta",
}) => {
  const windowLocation = typeof window !== "undefined" && window.location
  const params = getSearchParamsFromUrl()
  const pathname = windowLocation?.pathname ? windowLocation?.pathname : ""
  const cleanPathName =
    pathname.charAt(0) === "/" ? pathname.replace("/", "") : pathname
  const chunks = cleanPathName.split("/")
  const proposalIndex = chunks.findIndex((chunk) => chunk === proposalRoute)
  if (proposalIndex >= 0) chunks.splice(proposalIndex)
  const homePathname = chunks.join("/")
  if (!withParams) return homePathname
  return `${homePathname}${params}`
}

export const scrollToTop = () => {
  if (!window) return
  window.scroll(0, 0)
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
}

export const focusOnLogo = () => {
  const element = document?.querySelector("a.ds-header__logo-link")
  if (element) {
    element.focus()
    element.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}

export const debounce = function (callback, delay) {
  let debouncer
  return function () {
    var context = this
    var args = arguments
    clearTimeout(debouncer)
    debouncer = setTimeout(function (_) {
      return callback.apply(context, args)
    }, delay)
  }
}

export const getUrlQueryParams = (search) => {
  if (!search) return null

  const queries = new URLSearchParams(search).entries()

  const objQueries = [...queries].reduce((obj, item) => {
    const [key, value] = item
    return {
      ...obj,
      [key]: value,
    }
  }, {})

  return objQueries
}

export const removeSpecialCharsFromAddress = (addressString) => {
  if (addressString === null || addressString === undefined)
    return addressString
  const regex = /[^a-zA-Z0-9áéíóúàâêôãõüçñöÁÉÍÓÚÀÂÊÔÃÕÜÇÑÖ_ ]/gm
  const hiphenReplacedString = addressString.replace(/[-]+/gm, " ")
  const sanitizedString = hiphenReplacedString.replace(regex, "")
  return sanitizedString
}

export const renewTime = (valid_time, server_time) => {
  const toMilliseconds = (secs) => secs * 1000
  const secondsBeforeRenew = 60 * 5
  const renewSeconds = valid_time - server_time - secondsBeforeRenew

  return toMilliseconds(renewSeconds) + Date.now()
}
