import {
  ENVIRONMENT,
  GIT_VERSION,
  IS_DATADOG_ENABLED,
} from "gatsby-env-variables"

import { datadogRum } from "@datadog/browser-rum"

const init = () => {
  if (!IS_DATADOG_ENABLED) return

  const version = {
    ...(ENVIRONMENT === "production" ? { version: GIT_VERSION } : {}),
  }

  datadogRum.init({
    applicationId: "48ed817a-f230-4479-9b51-94ffc5b683d0",
    clientToken: "pubbd0dcfd62d0d3f12e974f6fde13532cc",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "itau-jn6-app-garantidos-www",
    env: `${ENVIRONMENT}`,
    ...version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    actionNameAttribute: "datadog-action",
  })
}

export default {
  init,
}
